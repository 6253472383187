//answer-the-question
var __decorate = this && this.__decorate || function(decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for(var i = decorators.length - 1; i >= 0; i--)if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import gsap from 'gsap';
import { Component, register } from 'ovee.js';
let _class = class _class extends Component {
    init() {
        this.$on('answer-clicked', window, (e)=>{
            this.$element.classList.add('is-loading');
            // this.$element.classList.add(`answer--${e.detail}`);
            this.createAnswer(e.detail);
            setTimeout(async ()=>{
                this.$element.classList.add('is-loaded');
                this.$element.classList.remove('is-loading');
                await gsap.to(this.$element.querySelector('img'), {
                    scale: .8,
                    duration: .3,
                    ease: 'none.none',
                    repeat: 6,
                    yoyo: true
                });
                this.heartAnimationComplete();
            }, 800);
        });
    }
    async heartAnimationComplete() {
        const loader = this.$element.querySelector('.answer__heart');
        const content = this.$element.querySelector('.answer__container');
        await gsap.to(loader, {
            opacity: 0,
            duration: .3
        });
        loader.remove();
        gsap.to(content, {
            opacity: 1,
            duration: .8,
            delay: 1
        });
    }
    createAnswer(answer) {
        const heading = answer === 'no' ? 'Meh 😢' : 'Juuuupi 🥰';
        const rose = answer === 'no' ? '🥀' : '🌹';
        const subheading = answer === 'no' ? 'Okej, może następnym razem.' : 'Oto róża dla ładnej pani!';
        const container = document.createElement('div');
        container.classList.add('answer__container');
        container.innerHTML = `
			<p class="answer__heading">${heading}</p>
			<p class="answer__rose">${rose}</p>
			<p class="answer__subheading">${subheading}</p>
		`;
        this.$element.appendChild(container);
    }
};
_class = __decorate([
    register('answer-the-question')
], _class);
export { _class as default };
